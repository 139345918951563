import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { HelperService } from '../helper.service';

@Injectable({
  providedIn: 'root'
})
export class DynamicRoutesguardGuard implements CanActivate,CanActivateChild {
  constructor(public _HelperService: HelperService, public _Router: Router) {

  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): 
  boolean | import("@angular/router").UrlTree | Observable<boolean | 
  import("@angular/router").UrlTree> | Promise<boolean | import("@angular/router").UrlTree> { 
    if (state.url) {
      if (this._HelperService.UserAccount.Features.find(x => childRoute.data.accessName.includes(x.SystemName))) {
        return true;
      } else {
        this._Router.navigate([this._HelperService.AppConfig.Pages.System.NotAccess]);
        return false;
      }
    }
    this._HelperService.Poster_Crop_Clear();
    this._HelperService.Icon_Crop_Clear();
    this._HelperService.AppConfig.ShowHeader = true;
    this._HelperService.FullContainer = false;
    if (this._HelperService.GetStorage(this._HelperService.AppConfig.Storage.Account) != null) {
      return true;
    } else {
      this._Router.navigate([this._HelperService.AppConfig.Pages.System.Login]);
      return false;
    }
    return true;
  }

  canActivate( next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean { 
    if (state.url) {
      if (this._HelperService.UserAccount.Features.find(x => next.data.accessName.includes(x.SystemName))) {
        return true;
      } else {
        this._Router.navigate([this._HelperService.AppConfig.Pages.System.NotAccess]);
        return false;
      }
    }
    this._HelperService.Poster_Crop_Clear();
    this._HelperService.Icon_Crop_Clear();
    this._HelperService.AppConfig.ShowHeader = true;
    this._HelperService.FullContainer = false;
    if (this._HelperService.GetStorage(this._HelperService.AppConfig.Storage.Account) != null) {
      return true;
    } else {
      this._Router.navigate([this._HelperService.AppConfig.Pages.System.Login]);
      return false;
    }
    return true;
  }

}